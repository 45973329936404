<template>
  <div v-if="$canAndRulesPass(user, 'card.abcd_list_see')">
    <page-heading
      heading="ABCD свод"
    >
      <span slot="help"></span>
      <div slot="right"></div>
    </page-heading>

    <div style="display: flex; justify-content: space-between; flex-flow: wrap;align-items: flex-start;align-content: flex-start;">
      <user-unique-prop-values-select
        placeholder="Дирекция"
        style="width: 250px"
        v-model="filters.directions"
        prop="direction"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>
      <user-unique-prop-values-select
        placeholder="Подразделение второго уровня"
        style="width: 300px"
        v-model="filters.subdivisions"
        prop="subdivision"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>
      <user-unique-prop-values-select
        placeholder="Подразделение третьего уровня"
        style="width: 300px"
        v-model="filters.companies"
        prop="company"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>
      <user-select
        placeholder="ФИО НР"
        style="width: 300px"
        v-model="filters.direct_chief_user_id"
        :multiple="false"
        @selected="filtersChanged"
      ></user-select>
      <user-unique-prop-values-select
        placeholder="Должность сотрудника"
        style="width: 300px"
        v-model="filters.positions"
        prop="position"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>
      <user-unique-prop-values-select
        placeholder="Грейд"
        style="width: 100px"
        v-model="filters.grades"
        prop="grade"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>
    </div>

    <el-table
      v-loading="loading"
      style="margin-top: 20px"
      size="mini"
      empty-text="Нет карт"
      :data="cards"
      @sort-change="sortChanged($event, loadCards)"
    >
      <el-table-column prop="user.last_name" label="ФИО" :sortable="setIsSortable('user.last_name')">
        <template slot-scope="scope">
          <router-link
            class="table-router-link"
            target="_blank"
            :to="{ name: 'Card', params: { id: scope.row.id } }"
          >
            {{ scope.row.user.full_name }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="user.direction" label="Дирекция" :sortable="setIsSortable('user.last_name')"></el-table-column>
      <el-table-column prop="user.subdivision" label="Подразделение второго уровня" :sortable="setIsSortable('user.subdivision')"></el-table-column>
      <el-table-column prop="user.company" label="Подразделение третьего уровня" :sortable="setIsSortable('user.company')"></el-table-column>
      <el-table-column prop="user.directChief.full_name dc" label="ФИО непосредственного руководителя" :sortable="setIsSortable('user.directChief.full_name dc')">
        <template slot-scope="scope">
          {{scope.row.user.chief_direct ? scope.row.user.chief_direct.full_name : ''}}
        </template>
      </el-table-column>
      <el-table-column prop="user.position" label="Должность сотрудника" :sortable="setIsSortable('user.position')"></el-table-column>
      <el-table-column width="90" prop="user.grade" label="Грейд" :sortable="setIsSortable('user.grade')"></el-table-column>
      <el-table-column prop="info.pre_point" label="Оценка руководителя"></el-table-column>
      <el-table-column prop="info.final_point" label="Итоговая оценка">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.info.final_point"
            style="width: 120px"
            size="small"
            placeholder="Выберите"
            @change="savePoint(scope.row)"
          >
            <el-option label="A" value="A"></el-option>
            <el-option label="B" value="B"></el-option>
            <el-option label="C" value="C"></el-option>
            <el-option label="D" value="D"></el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table>

    <paginator
      :paginator.sync="paginator"
      @page-changed="loadCards"
    ></paginator>


  </div>
</template>

<script>

import {mapGetters} from "vuex";
import PageHeading from "@/components/PageHeading.vue";
import {paginator} from "@/mixins/paginator";
import {sorter} from "@/mixins/sorter";
import Paginator from "@/components/Paginator.vue";
import {cardInfoField} from "@/mixins/cardInfoField";
import UserUniquePropValuesSelect from "@/components/filters/users/UserUniquePropValuesSelect.vue";
import UserSelect from "@/components/filters/users/UserSelect.vue";

export default {
  name: 'CardPointsList',
  mixins:[paginator, sorter, cardInfoField],
  components: {UserSelect, UserUniquePropValuesSelect, Paginator, PageHeading},

  data() {
    return {
      loading: false,
      card: null,
      cards: [],
      filters:{
        directions: [],
        subdivisions: [],
        companies: [],
        positions: [],
        grades: [],
        direct_chief_user_id: null,
      }
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  mounted() {
    this.loadCards();
  },
  methods: {
    filtersChanged(){
      this.loadCards();
    },

    loadCards() {
      this.loading = true;
      this.$api.card.search({
        ...this.filters,
        subordinates: 3,
        card_type: 'abcd',
        expand: 'user,user.chief_direct, info, permissions',

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator, sorter}) => {
          this.cards = data.cards;
          this.paginatorFill(paginator);
          this.sorterFill(sorter);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    savePoint(card) {
      this.card = card;
      this.loading = true;
      this.infoFieldChanged('final_point')
        .then(() => {
        })
        .finally(() => {
          this.loading = false;
        })
    },
  }
}

</script>

<style lang="scss">

</style>